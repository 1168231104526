
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/inspection";
import * as jiekou from "@/api/safecheck";
import File from "@/api/file";

@Options({})
export default class extends Vue {
  private submitform = {
    status: null,
    wayId: "",
    remark: "",
    pointId: "",
    fileUrl: "",
    rectifyId: null,
    rectifyName: null
  };
  @Watch("personName")
  onPersonNameChanged(newValue: string, oldValue: string) {
    this.refreshing = true;
    this.onRefresh();
  }
  @Watch("result")
  onResultChanged(newValue: string, oldValue: string) {
    this.flag = true;
  }
  private uploader: any[] = [];
  private checkList: any[] = [];
  private refreshing = false;
  private show = false;
  private result = "";
  private fieldValue = "";
  private personName = "";
  private loading = false;
  private finished = false;
  private flag = false;
  private isTrue = false;

  mounted() {
    this.isTrue = true;
    this.submitform.pointId = this.$route.query.pointId as any;
    this.submitform.wayId = this.$route.query.wayId as any;
  }
  protected showPopup() {
    this.show = true;
  }

  onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }

  protected closePopup() {
    this.show = false;
  }

  protected onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.checkList = [];
        this.refreshing = false;
      }
      jiekou
        .pageListUser({
          pageNum: parseInt(this.checkList.length / 20 + "") + 1,
          pageSize: 20,
          projectId: this.$store.state.project.projectinfo.projectId,
          name: this.personName,
        })
        .then((res) => {
          this.checkList = [...this.checkList, ...res.data.records];
          this.loading = false;
          if (this.checkList.length >= res.data.total) {
            this.finished = true;
          }
        });
    }, 500);
  }

  protected onFinish() {
    let index = this.checkList.findIndex((item) => {
      if (item.userId === this.result) {
        return true;
      }
    });
    this.submitform.rectifyId = this.checkList[index].userId;
    this.submitform.rectifyName = this.checkList[index].name;
    this.fieldValue = this.checkList[index].name;
    this.show = false;
  }

  afterRead(files: any) {
    const that = this
    let filearr = null;
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }
    filearr.map((file: any) => {
      file.status = "uploading";
      file.message = "上传中...";
      File.uploadFile(
        "photographmobile/",
        file.file.name,
        file.file,
        true
      ).then((res: any) => {
        that.isTrue = false;
        file.status = "done";
        file.id = res.data.id;
        file.url = res.data.url;
      });
    });
  }

  protected onSubmit() {
	this.isTrue = true;
    let arr: any[] = [];
    this.uploader.map((li) => {
      arr.push(li.url);
    });
    this.submitform.fileUrl = arr.join(",");
    api.saveRecord(this.submitform).then((res: any) => {
      if (res.code === 0) {
        this.$toast.success({
          message: res.msg,
          forbidClick: true,
          onClose: () => {
            this.$router.go(-1);
          },
        });
      } else {
        this.$toast.fail(res.msg);
      }
    });
  }
}
